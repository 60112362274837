(function () {
  var elcVideo = window.elcVideo || {};
  var videojs = window.videojs || {};
  var elcVideoObject = window.elcVideoObject || {};
  var videoBlocks = window.videoBlocks || [];
  var posterBlock = '';
  var elcVideos = [];
  const bps = Unison.fetch.all();
  const isMobile = () => {
    if (window.innerWidth <= parseInt(bps.medium, 10)) {
      return true;
    };

    return false;
  };
  const isYoutube = (el) => {
    if (el.dataset.setup.indexOf('youtube') !== -1) {
      return true;
    }

    return false;
  };
  const isiOS = window.deviceName === 'ios' ? true : false;
  const compileVideoOptions = (el, source) => {
    var ytOptions;
    var vjsOptions;
    var videoOptions;
    const videoBlockElement = el.parentElement.closest('.js-video-block');
    const videoPlaysInline = videoBlockElement.dataset.playInline ?
      videoBlockElement.dataset.playInline :
      false;
    const videoAutoplay = el.dataset.autoplayVideo ? el.dataset.autoplayVideo : false;
    const videoMutedOnLoad = el.dataset.muteVideoOnLoading ? el.dataset.muteVideoOnLoading : false;

    vjsOptions = {
      autoSetup: false,
      autoplay: videoAutoplay,
      muted: videoMutedOnLoad,
      customControlsOnMobile: !isiOS,
      inactivityTimeout: 500,
      fluid: true,
      nativeControlsForTouch: isiOS,
      preload: 'auto',
      preferFullWindow: isiOS,
      responsive: true,
      restoreEl: true,
      sources: source,
      techOrder: [
        'youtube',
        'html5'
      ]
    };
    if (isYoutube(el)) {
      ytOptions = {
        youtube: {
          ytControls: 0,
          modestbranding: 1,
          origin: window.location.origin,
          enablejsapi: !isiOS,
          customVars: {
            fs: isiOS,
            playsinline: videoPlaysInline
          }
        }
      };
    }
    videoOptions = {
      ... vjsOptions,
      ... ytOptions
    };

    return videoOptions;
  };
  const injectResponsiveImageSource = (target, obj, file) => {
    let source = document.createElement('source');
    let videoObj = obj;

    videoObj.mobilePoster = {};
    videoObj.mobilePoster.src = file
      .match('src=(.*).alt')[1]
      .replaceAll('"', '');
    source.dataset.srcset = videoObj.mobilePoster.src;
    source.srcset = videoObj.mobilePoster.src;
    source.media = '(max-width:1023px)';
    target.prepend(source);
  };
  var videoBlock = {
    init: function () {
      videoBlocks = document.querySelectorAll('.js-video-block');

      if (videoBlocks.length === 0) {
        return;
      }

      videoBlocks.forEach((video) => {
        video.classList.remove('video-block--fullscreen');
        elcVideo = video.querySelector('elc-video-js');
        posterBlock = video.querySelector('.js-videojs-landing__image .mantle-media-asset picture');
        if (elcVideo !== null && elcVideo.dataset !== null) {
          const poster = video.dataset.mobileYoutubePoster || null;
          const vjsSetup = JSON.parse(elcVideo.dataset.setup);
          const desktopSource = [
            {
              type: vjsSetup.sources[0].type,
              src: vjsSetup.sources[0].src
            }
          ];
          const mobileSource = (video.dataset.mobileYoutubeSource) ?
            [
              {
                type: 'video/youtube',
                src: `https://www.youtube.com/watch?v=${video.dataset.mobileYoutubeSource}&rel=0`
              }
            ] :
            desktopSource;

          elcVideoObject.setup = {
            desktop: compileVideoOptions(elcVideo, desktopSource),
            mobile: compileVideoOptions(elcVideo, mobileSource)
          };
          elcVideoObject.setup.desktop.aspectRatio = '16:9';
          elcVideoObject.setup.mobile.aspectRatio = '4:5';
          if (isYoutube(elcVideo)) {
            [elcVideo.setAttribute('allow', `autoplay 'self' ${window.location.origin}`)];
          }
          if (poster) {
            injectResponsiveImageSource(posterBlock, elcVideoObject, poster);
          }
          if (posterBlock) {
            posterBlock.style.opacity = 1;
          }
          elcVideos.push(elcVideoObject);
        }
      });
    },

    toggleResponsive: function (el, obj) {
      if (el !== null) {
        const videoObject = obj;
        const videoElement = el;
        var containerBlock = videoElement.closest('.js-video-block');

        if (isMobile()) {
          videoObject.setup.mobile.poster = containerBlock.dataset.mobileYoutubePoster
            || videojs.options.poster;
          videoElement.dataset.setup = JSON.stringify(videoObject.setup.mobile);
          containerBlock.classList.remove('js-video-desktop');
          containerBlock.classList.add('js-video-mobile');
        } else {
          videoElement.dataset.setup = JSON.stringify(videoObject.setup.desktop);
          containerBlock.dataset.setup = JSON.stringify(videojs.options);
          containerBlock.classList.remove('js-video-mobile');
          containerBlock.classList.add('js-video-desktop');
        }
        videojs.optionsources = isMobile() ?
          elcVideoObject.setup.mobile.sources :
          elcVideoObject.setup.desktop.sources;
      }
    },

    toggleFullscreen: function (videoJsBlock) {
      var targetVideoBlock = videoJsBlock.parentElement.closest('.js-video-block');

      if (!videoBlocks) {
        return;
      }

      targetVideoBlock.classList.toggle('video-block--fullscreen');
    }
  };

  Drupal.behaviors.videoBlockV2 = {
    attached: false,
    attach: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }
      self.attached = true;

      videoBlock.init(context);
      videoBlock.toggleResponsive(elcVideo, elcVideoObject);
    }
  };

  Unison.on('change', function () {
    videoBlock.toggleResponsive(elcVideo, elcVideoObject);
  });

  document.addEventListener('fullscreenchange', function () {
    var videoJsBlock = event.target;

    if (videoJsBlock) {
      videoBlock.toggleFullscreen(videoJsBlock);
    }
  });
})();
